/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap) => {

        let homeAnimTl = gsap.timeline({ paused: true })

        homeAnimTl.to({}, { duration: 1.6 })

        homeAnimTl.from('.home-anim .col:nth-child(odd)', {
            y: '+=150',
            autoAlpha: 0,
            duration: 2.4,
            ease: 'power4.out'
        }, 'grid')

        homeAnimTl.from('.home-anim .col:nth-child(even)', {
            y: '-=100',
            autoAlpha: 0,
            duration: 2.4,
            ease: 'power4.out'
        }, 'grid')

        homeAnimTl.from('.home-anim .home-anim-overlay', {
            autoAlpha: .2,
            duration: 1,
            ease: 'power2.out'
        }, 'grid+=.8')

        homeAnimTl.from('.banner-home .item-title', {
            y: '+=50',
            autoAlpha: 0,
            duration: .4,
            ease: 'power1.out'
        }, 'title')

        homeAnimTl.from('.banner-home .item-link', {
            y: '+=20',
            autoAlpha: 0,
            duration: .4,
            ease: 'power1.out'
        }, 'title+=.2')

        $('body').on('dom:ready', function () {

            homeAnimTl.play()

        });

    }
}
