/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import incubator from '@pages/incubator.js';
import transactions from '@pages/transactions.js';
import animations from '@pages/animations.js';
import home_anim from '@pages/home_anim.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, Menu, Kira]
  },
  {
    'file': main,
    'dependencies': [app, Swiper]
  },
  {
    'file': news,
    'dependencies': [app],
    'resolve': '#page-news-archive'
  },
  {
    'file': incubator,
    'dependencies': [app],
    'resolve': '#page-incubator-archive'
  },
  {
    'file': transactions,
    'dependencies': [app],
    'resolve': '#page-transactions-archive'
  },
  {
    'file': home_anim,
    'dependencies': [app, gsap],
    'resolve': '#page-home'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

