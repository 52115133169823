/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
		const
			$body = $('body')
			;


		/*
		|
		| Réduir le menu au scroll
		|-------------------------
		*/
		function minimenu() {
			if ($(window).scrollTop() > 20) {
				$('#header').addClass('sticky-menu');
			} else {
				$('#header').removeClass('sticky-menu');
			}
		}

		minimenu();
		$(window).scroll(function () {
			minimenu();
		});

		/*
		|
		| Accordion
		|-------------------------
		*/
		$(document).ready(function () {
			$('.acc-title').on("click", function () {
				let clickedData = $(this).data("parent");
				if ($(this).parent('.accordion-item').hasClass("active")) {
					$(this).siblings(".accordion-body").slideDown();
				}
				else {
					$("#" + clickedData + " .accordion-body").slideUp(400);
					$("#" + clickedData + " .accordion-item").removeClass("active");
					$(this).siblings(".accordion-body").slideDown();
					$(this).parent(".accordion-item").addClass("active");
				}
			})
		});


	}
}
