/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $loadMoreBtn = $('.load-more-btn'),
            $postsContainer = $('.loaded-posts'),
            $loadMoreContainer = $('.load-more-container'),
            $loadMore = $('.load-more'),
            $loaderContainer = $('.loader-container')
            ;

        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        $loadMoreBtn.on('click', function (e) {

            e.preventDefault();

            abort(xhr);

            let offset = $postsContainer.find('.custom-card').length;
            let url = `/ajax/incubator/${offset}/?ajax-lang=${$('html').data('lang')}`;

            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',

                success: (response, status) => {

                    $postsContainer.append(response);

                    if ($(response).find('.no-more-post').length) {
                        $loadMoreContainer.remove();
                    }

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });

        });

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null) {
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if ($list !== null) {
                $list.find('.loading--ajax').hide();
            }
        }

    }
}
